import ApplicationLayoutBodyNavigation from '@rio-cloud/rio-uikit/ApplicationLayoutBodyNavigation';
import SubNavigation from '@rio-cloud/rio-uikit/SubNavigation';
import { NavLink } from 'react-router';

export const AppSubNavigationContainer = () => (
    <ApplicationLayoutBodyNavigation className={'has-offset'}>
        <SubNavigation
            navItems={[
                {
                    key: '1',
                    route: (
                        <NavLink to='/activation' className={'line-height-14 height-100pct'}>
                            <div className='display-flex height-100pct align-items-center'>
                                <span>Activations</span>
                            </div>
                        </NavLink>
                    ),
                },
                {
                    key: '2',
                    route: (
                        <NavLink to='/integrated-offer' className={'line-height-14 height-100pct'}>
                            <div className='display-flex height-100pct align-items-center'>
                                <span>Integrated Offer</span>
                            </div>
                        </NavLink>
                    ),
                },
            ]}
        />
    </ApplicationLayoutBodyNavigation>
);
